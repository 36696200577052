<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-full  flex w-full vx-row no-gutter items-center justify-center bg-img"
    id="page-login"
  >
    <!-- <div class="vx-col w-full sm:m-0 "> -->
    <!-- <div slot="no-body" class="full-page-bg-color bg-white "> -->
    <div
      class="md:w-2/5   w-full mx-auto no-gutter justify-center items-center"
    >
      <div class=" center-block">
        <div class="  absolute inset-y-0 left-0">
          <!-- <img
            src="@/assets/images/logo.png"
            alt="login"
            class="w-full bg-local logoSize p-2"
          /> -->
        </div>
      </div>
      <!-- <div class="vx-col  sm:w-1/4 md:w-full lg:w-1/2 d-theme-dark-bg "> -->
      <div class="lg:ml-24 lg:mt-24 px-8 pt-8 login-tabs-container">
        <vx-card class=" h-68  ">
          <!-- style="background-image:url('https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_960_720.jpg')" -->
          <div class="md:w-1/2 mx-auto items-center justify-start ">
              <img
                src="@/assets/images/logo.png"
                alt="login"
                class="bg-local logoSize"
                style="margin:auto"
              />
          </div>
          <div class="vx-card__title mb-4 pt-4">
            <h4 class="mb-4 font-bold text-md">{{ $t("Login") }}</h4>
            <p class="text-danger text-sm font-bold">
              {{ $t("WelcomeBack") }}
            </p>
          </div>

          <login-jwt></login-jwt>
        </vx-card>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import LoginJwt from "./LoginJWT.vue";
import Logo from "@/layouts/components/Logo.vue";

export default {
  components: {
    LoginJwt,
    Logo
  }
};
</script>

<style lang="scss">
.login-tabs-container {
  height: calc(100vh - 120px);
  min-height: calc(100vh - 220px);

  .con-tab {
    padding-bottom: 14px;
  }
  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
<style >
.logoSize{
  width: 200px;
  height: 50px;

}
</style>